<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Create a new Category</CCardTitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="name"
                      label="Name"
                      placeholder="Enter the name of the category"
                      v-model="part_category.name"
                    />
                  </CCol>
                
                  <CCol sm="6">
                    <CSelect
                      label="Type"
                      placeholder="select"
                      :options="[
                        { label: 'Bicycle', value: 'bicycle' },
                        { label: 'Accessories', value: 'accessories' },
                        { label: 'E-bike', value: 'e-bike' },
                      ]"
                      :value.sync="part_category.product_type"
                    />
                  </CCol>
                
                  <CCol sm="6">
                    <CInputFile
                      name="image"
                      label="Image"
                      description="Maximum total upload size is 1MB.
                    Images can be compressed using: imagecompressor.com"
                      v-on:change="setImage"
                      required
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="createPartCategory"
            >
              <CIcon name="cil-check-circle" />
              Create
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import PartCategoryAPI from "/app/src/api/part-category.js";

export default {
  name: "Create",
  data: () => {
    return {
       part_category: {
        name: "",
      },
      image: {},
      partCategoryAPI: new PartCategoryAPI(),
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },
  created: function () {},
  methods: {
    createPartCategory: function () {
      let self = this;

      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");

      this.partCategoryAPI
        .create(this.part_category, this.image)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          self.$router.push({ path: `/manager/part-categories/${result.id}` });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.part_category.name.length < 1) return "Name is required";
      return true;
    },
    setImage: function (files, e) {
      this.image = files[0];
      console.log(this.image);
    },
  },
};
</script>
